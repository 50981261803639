import {
    ApiResponseError
} from "./ApiResponseError";

export class ApiError extends Error {

    public constructor(error: ApiResponseError) {

        super(error.message);

        Object.setPrototypeOf(this, ApiError.prototype);

        this.name = 'ApiError';
        this.code = error.code;
        this.stack = Error().stack;
    }

    public code: string
}